<template>
  <div class="page-layout">
    <c-header-lv2></c-header-lv2>
    <router-view></router-view>
  </div>
</template>

<script>
import CHeaderLv2 from "../CheaderLv2";
export default {
  name: "page-layout",
  components: {
    [CHeaderLv2.name]: CHeaderLv2,
  },
};
</script>

<style>

</style>