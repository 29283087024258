<template>
  <div class="c-header-lv2"  @touchmove.prevent>
    <!-- header -->
    <div class="header">
      <div class="back" @click="onPrevToPage">
        <c-image iUrl="icon-back" iWidth="7" iHeight="14"></c-image>
      </div>

      <c-image iUrl="logo_s" iWidth="46.3" iHeight="22" @imgClick="onPageToHome"></c-image>

        <template v-if="isLogin">
            <div class="menu" @click="showNav=true;" v-if="!showNav">
                <div class="avatar">
                    <van-image :src="userData.user.head_img" :width="26 / 37.5 + 'rem'" :height="26 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                </div>
            </div>
            <div class="menu" @click="showNav=false;" v-else>
                <c-image iUrl="icon-close" iWidth="12" iHeight="12"></c-image>
            </div>
        </template>
        <template v-else>
            <div class="menu"></div>
        </template>


      

    </div>

    <!-- nav -->
    <div class="nav" :class="{ on: showNav }">

    <!-- user info -->
      <div class="nav_top">
            <div class="nav_top_container">
                <div class="nav_user_info">
                    <div class="nav_info">
                        <div class="nav_user">
                            <h4>{{userData.user.nickname}}</h4>
                        </div>
                        <div class="nav_desc">ID: {{userData.user.id}}</div>
                    </div>
                    <div class="nav_avatar">
                        <van-image :src="userData.user.head_img" :width="48 / 37.5 + 'rem'" :height="48 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                        <c-image iUrl="icon-vip_s" iClass="vip" iWidth="18" iHeight="18" v-if="userData.vip_flag"></c-image>
                    </div>
                </div>
                <div class="nav_data">
                    <div class="nav_data_item">
                        <div class="num">{{userData.work_num}}</div>
                        <div class="name">作品</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{totalAmount}}</div>
                        <div class="name">提现</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{userData.collect_num}}</div>
                        <div class="name">收藏</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{userData.invite_num}}</div>
                        <div class="name">邀请</div>
                    </div>
                </div>
            </div>
        </div>

      <div class="nav_item" v-for="item in menus" :key="item.name" @click="onNavToPage(item.route)">
          <div class="content">
              <div class="left">
                   <c-image :iUrl="item.img" iWidth="38" iHeight="38"></c-image>
                   <p>{{item.name}}</p>
              </div>
               <c-image iUrl="icon-right" iWidth="7" iHeight="13"></c-image>
          </div>
      </div>

        <div class="btn_exit" @click="onNavToHome">退出登录</div>

    </div>
  </div>
</template>

<script>
import { Image } from "vant";
import CImage from "./CImage.vue";
import { menus } from "../router/config";
import { mapState, mapMutations } from "vuex";
import { toastSuccess } from "../lib/util";
export default {
    name: "c-header-lv2",
    components: {
        [CImage.name]: CImage,
        [Image.name]: Image,
    },
    data: function () {
        return {
            menus: menus,
            showNav: false,
        };
    },
    computed: {
        ...mapState({
            userData: (state) => state.me.userData,
            isLogin: state => state.isLogin
        }),
        totalAmount() {
            return parseInt(this.userData.user.total_withdraw_amount)
        }
    },
    methods: {
        ...mapMutations({
            setIsLogin: "SET_IS_LOGIN"
        }),
        onPageToLogin() {
            this.$router.push({
                name: "login",
            });
        },
        onPageToHome() {
            this.$router.push({
                name: "home",
            });
            this.showNav = false;
        },
        onNavToHome() {
            this.$cookies.remove("user_id");
            this.$cookies.remove("invite_code");
            
            this.$router.replace({ name: "home" }).then(() => {
                toastSuccess("退出成功");
            }).catch((err) => { });

            this.setIsLogin(false);

            if (this.$route.name == "home") toastSuccess("退出成功");
        },
        onPrevToPage() {
            this.$router.go(-1);
            this.showNav = false;
        },
        onNavToPage(route) {
            if (route == "homepage") {
                this.$router.push({
                    name: "homepage",
                    params: { id: this.$cookies.get("user_id"), clickFrom: "nav", enterType: "click" },
                    query: { tab: 0 }
                });
            } else {
                this.$router.push({
                    name: route,
                    params: {
                        clickFrom: "nav",
                    },
                });
            }

            this.showNav = false;
        },
    },
    created() {
        if (this.$cookies.isKey("user_id")) {
            this.setIsLogin(true)
        }
    },
    activated() {
        if (this.$cookies.isKey("user_id")) {
            this.setIsLogin(true)
        }
    },
};
</script>

<style>
.c-header-lv2{width:100%;height:47px;background:#fff;position:fixed;left:0;top:0;z-index:200;transform: translateZ(0);-webkit-transform: translateZ(0);}
.c-header-lv2 .header{overflow:hidden;width:100%;height:47px;display:flex;justify-content:space-between;align-items:center;z-index: 300; background: #fff;border-bottom:1px solid #ededed;}
.c-header-lv2 .back{width:47px; height: 47px; display: flex; justify-content: center; align-items: center;}
.c-header-lv2 .menu{width:48px; height: 47px; display: flex; justify-content: center; align-items: center;}
.c-header-lv2 .nav{width:100%;height:100vh;background:#f5f5f5;position:absolute;left:0;top:-100vh;z-index:90;transition:top 0.2s ease-out; opacity: 0;}
.c-header-lv2 .nav.on{top:47px; opacity: 1;}
.c-header-lv2 .nav_item{padding:0 20px}
.c-header-lv2 .nav_item .content{width: 100%; height:62px ;border-bottom: 1px solid #EDEDED; display: flex; justify-content: space-between; align-items: center;}
.c-header-lv2 .nav_item .left{display: flex; justify-content: flex-start; align-items: center;}
.c-header-lv2 .nav_item .left p{font-size: 16px;color: #2E2E30; margin-left: 12px; letter-spacing: 1px;}
.c-header-lv2 .avatar{width:26px; height: 26px; border-radius: 50%; overflow: hidden;}
.c-header-lv2 .btn_exit{width:335px; height: 46px; border-radius: 27px; background: #F85659; color: #fff;font-size: 16px; line-height: 46px; text-align: center; margin: 32px auto 0;}
.c-header-lv2 .nav_top{padding: 20px 20px 0;}
.c-header-lv2 .nav_top_container{background:#fff; border-radius:4px ;padding: 20px 20px 16px;}
.c-header-lv2 .nav_user_info{ display: flex; justify-content: space-between; align-items: center;}
.c-header-lv1 .nav_avatar{width:48px; height: 48px; border-radius: 50%; position: relative; z-index: 1;}
.c-header-lv1 .nav_avatar .van-image img {width: 100%; height: 100%; border-radius: 50%;} 
.c-header-lv1 .nav_avatar .vip {position: absolute; position: absolute; right: 0; bottom: 0px;}
.c-header-lv2 .nav_info{flex: 1;}
.c-header-lv2 .nav_user{display: flex; justify-content: flex-start; align-items: center;}
.c-header-lv2 .nav_user h4{display: inline-block; max-width: 160px; margin-right:11px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis; font-size: 20px; color: #2E2E30; font-weight: normal;}
.c-header-lv2 .nav_user_tag{flex:1; display: flex; justify-content: flex-start; align-items: center;}
.c-header-lv2 .nav_user_tag .tag_item{margin-right:8px ;}
.c-header-lv2 .nav_desc{font-size: 12px;color: #B3B3B3; width:230px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis; margin-top: 4px;}
.c-header-lv2 .nav_data{margin-top: 25px; display: flex; justify-content: space-between; align-items: center;}
.c-header-lv2 .nav_data_item .name{font-size: 12px;color: #B3B3B3; text-align: center;}
.c-header-lv2 .nav_data_item .num{font-size: 16px; text-align: center; color:#2E2E30 ; margin-bottom: 4px;}
.c-header-lv2 .nav_data_item .red{color: #F85659;}

</style>